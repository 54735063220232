import { ChangeEventHandler, Dispatch, SetStateAction, useMemo, useState } from "react";
import './form.css';

const SYMBOL_REGEX = new RegExp("^([a-zA-Z0-9]+|[a-zA-Z0-9]+\\.[a-zA-Z0-9]+)$");

export default function SymbolInput(props: SymbolInputProps) {
    const { id, stockSymbol, setStockSymbol } = props;

    const [isSymbolValid, setIsSymbolValid] = useState<boolean>(checkSymbol(stockSymbol));
    const className = useMemo<string>(() => {
        return `${props.className ?? ''} ${isSymbolValid ? '' : 'invalid'}`;
    }, [props.className, isSymbolValid]);

    return <>
        <input
            className={className}
            type="text"
            value={stockSymbol}
            id={id}
            onChange={handleInputChange(setStockSymbol)}
            onBlur={validateInput(setIsSymbolValid)}
        />
        {isSymbolValid || <small>Must contain only letters or use a period to specify exchange (ex TD.TO)</small>}
    </>;
}

function handleInputChange(setInput: Dispatch<SetStateAction<string>>): ChangeEventHandler<HTMLInputElement> {
    return (event) => setInput(event.target.value.toLocaleUpperCase().trim());
}

function checkSymbol(symbol: string): boolean {
    return symbol.length < 1 || SYMBOL_REGEX.test(symbol);
}

function validateInput(setIsSymbolValid: Dispatch<SetStateAction<boolean>>): React.FocusEventHandler<HTMLInputElement> {
    return (event) => {
        if (event.target.value.length > 0) {
            setIsSymbolValid(checkSymbol(event.target.value));
        }
        return true;
    }
}

type SymbolInputProps = {
    className?: string;
    id: string;
    stockSymbol: string;
    setStockSymbol: React.Dispatch<React.SetStateAction<string>>;
}