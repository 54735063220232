export default function Footer() {
    return <div className="footer">
        <div>
            <p>Thanks for using WhatIfIBot! All data shown shuld be used for informational purposes only. Prices shown are not real-time and you should check the actual exchange for real time prices.</p>
        </div>
        <div>
            <p>
                We at WhatIfIBot strive to deliver accurate information for your use and we're continuously delivering updates to make the experience a better one. 
                If you spot any inaccuracies or bugs, please reach out to us at <a href="mailto:whatifibot@gmail.com" target="_blank" rel="noopener noreferrer">whatifibot@gmail.com</a>
                
            </p>
        </div>
    </div>;
}