import { Axios } from 'axios';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import BackendServiceBoundary, { BackendService } from './backend/BackendService';
import Header from './components/Header';
import UserForm, { UserFormInput } from './components/form/UserForm';
import Results from './components/results/Results';
// import MockBackendService from './mocks/MockBackendService';
import { getBackendUrl } from './Config';
import './App.css';
import Footer from './components/Footer';

ReactGA.initialize("G-LZJMSWZ7VC");

const axios = new Axios({ baseURL: getBackendUrl() });
const stockService: BackendServiceBoundary = new BackendService(axios);
// const stockService: BackendServiceBoundary = new MockBackendService();

function App() {
  const [userInput, setUserInput] = useState<UserFormInput>();

  return (
    <div>
      <Header />
      <UserForm onSubmit={setUserInput} />
      <Results userInput={userInput} service={stockService} />
      <Footer />
    </div>
  );
}

export default App;
