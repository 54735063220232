import { ReactNode, useState } from 'react';
import ReactDOM from 'react-dom';
import './modal.css';

export default function Modal(props: { children: ReactNode }) {
    const [show, setShow] = useState<boolean>(true);

    const closeModal = () => setShow(false);

    if (show) {
        return <>
            {ReactDOM.createPortal(<Backdrop onClick={closeModal} />, portalElement)}
            {ReactDOM.createPortal(<ModalOverlay onOk={closeModal}>{props.children}</ModalOverlay>, portalElement)}
        </>;
    } else {
        return <></>;
    }

}

const portalElement: HTMLElement = document.getElementById('overlays') as HTMLElement;

function Backdrop(props: { onClick: () => void }) {
    return <div className='backdrop' onClick={props.onClick} />;
}

function ModalOverlay(props: { children: ReactNode, onOk: () => void }) {
    return <div className='modal'>
        <div className='content'>{props.children}</div>
        <button className='button' onClick={props.onOk}>Ok</button>
    </div>;
}