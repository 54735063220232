export default function About() {
    return <div className="about">
        <h2>Key Features:</h2>
        <ol>
            <li><strong>Time-Travel Investing:</strong> Wondering how much your initial investment would be worth today if you had invested in the past? Input your stock purchase details, and WhatIfIBot will perform the calculations, giving you a real-time snapshot of your potential returns.</li>
            <li><strong>Comprehensive Stock Database:</strong> Our platform covers a vast array of stocks, ensuring that you can track the value of investments in companies across various industries. Stay informed about the performance of your favorite stocks or discover new opportunities.</li>
            <li><strong>Intuitive Interface:</strong> WhatIfIBot is designed with simplicity in mind. The intuitive interface makes it easy for users of all experience levels to navigate the platform effortlessly. No complex calculations or financial jargon – just straightforward results.</li>
            <li><strong>Historical Data Analysis:</strong> Dive deeper into the performance of your investments with our historical data analysis tools. View charts and graphs that illustrate the growth or decline of your portfolio over time, empowering you to make informed decisions for the future.</li>
            <li><strong>Real-Time Stock Prices:</strong> Stay up-to-date with the latest stock prices. WhatIfIBot integrates real-time data to ensure that your investment valuations are accurate and reflective of current market conditions.</li>
        </ol>

        <h2>How It Works:</h2>
        <ol>
            <li><strong>Input Your Investment Details:</strong> Enter the stock symbol, purchase date, and the amount you invested to get started. Default exchange is the NYSE, if you want to specify a different stock exchange then add a period followed by the exhange symbol. For Example: TD.TO</li>
            <li><strong>Instant Valuation:</strong> Click Calculate and receive an instant calculation of the value of your investment over time and the current value based on near real-time market prices.</li>
            <li><strong>Explore Insights:</strong> Delve into historical data, analyze trends, and gain insights into the performance of your portfolio.</li>
            <li><strong>Plan Your Next Move:</strong> Armed with accurate information, make informed decisions about your investments and strategize for future success.</li>
        </ol>

        <p>WhatIfIBot is here to empower you on your financial journey. Start tracking your investments with confidence and precision. Your financial future is just a click away!</p>
    </div>;
}