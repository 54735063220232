import { useEffect, useMemo, useState } from "react";
import BackendServiceBoundary from "../../backend/BackendService";
import { Stock } from "../../backend/Entities"

export default function useStockInfo(
    service: BackendServiceBoundary,
    symbol: string | undefined,
    initialInvestment?: number,
    investmentDate?: Date
): ResultViewModel {
    const [error, setError] = useState<string>('');
    const [status, setStatus] = useState<Status>('NOT_INIT');
    const [stock, setStock] = useState<Stock>();
    const [finalInvestmentValue, setFinalInvestmentValue] = useState<number>(0);

    const result = useMemo<ResultViewModel>(() => {
        let report = null;
        if (stock && initialInvestment) {
            report = {
                stock,
                investmentDate: stock.date,
                currentDate: new Date(),
                investmentDatePrice: stock.price,
                currentDatePrice: stock.currentMarketPrice,
                initialInvestmentValue: initialInvestment,
                finalInvestmentValue,
                totalInterest: ((stock.currentMarketPrice / stock.price) - 1) * 100,
                yearlyInterest: 1,
                currency: stock.currency,
                valueOverTimePoints: stock.priceOverTime || [],
            }
        }

        return { report, error, status};
    }, [stock, initialInvestment, finalInvestmentValue, error, status]);

    useEffect(() => {
        if (service && symbol && symbol.trim().length > 0 && investmentDate && investmentDate.getTime() < Date.now()) {
            setError('');
            setStatus('LOADING');
            service.getStockInfoForRange(symbol, 50, investmentDate).then(stock => {
                setStock(stock);
                setStatus('READY');
            }).catch((err) => {
                if (err instanceof Error) {
                    setError(err.message);
                    setStatus('ERROR');
                }
            });
        }
    }, [symbol, service, investmentDate]);

    useEffect(() => {
        if (stock && initialInvestment && initialInvestment > 0) {
            setFinalInvestmentValue((stock.currentMarketPrice / stock.price) * initialInvestment);
        }
    }, [stock, initialInvestment]);

    return result;
}

type ResultViewModel = {
    error: string;
    status: Status;
    report: null | {
        stock: Stock;
        investmentDate: Date;
        currentDate: Date;
        investmentDatePrice: number;
        currentDatePrice: number;
        initialInvestmentValue: number;
        finalInvestmentValue: number;
        totalInterest: number;
        yearlyInterest: number;
        currency: string;
        valueOverTimePoints: {
            value: number;
            date: Date;
        }[];
    }
}

type Status = 'NOT_INIT' | 'LOADING' | 'READY' | 'ERROR';