import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useMemo } from "react";
import { dateFormatter } from '../Formatters';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
ChartJS.defaults.color = '#FFFFFF';

export default function ResultGraph(props: ResultGraphProps) {
    const { dataPoints } = props;

    const chartData = useMemo(() => {
        if (dataPoints) {
            return {
                labels: dataPoints.map(p => dateFormatter.format(p.date)),
                datasets: [{
                    fill: 'origin',
                    borderColor: 'white',
                    backgroundColor: getBackgroundFillColor(dataPoints),
                    data: dataPoints.map(p => p.value),
                    tension: 0.2
                }]
            };
        }
        return { datasets: [] };
    }, [dataPoints]);

    return <Line className="chart" options={options} data={chartData} />;
}

type ResultGraphProps = {
    dataPoints: {
        value: number;
        date: Date;
    }[];
}

function getBackgroundFillColor(dataPoints: { value: number }[]): string {
    const diff = dataPoints[dataPoints.length - 1].value - dataPoints[0].value;
    
    let backgroundColor = 'rgba(255, 255, 255, 0.1)';
    if (diff > 0) {
        backgroundColor = 'rgba(0, 255, 0, 0.1)';
    } else if (diff < 0) {
        backgroundColor = 'rgba(255, 0, 0, 0.1)';
    }
    return backgroundColor;
}

const options: ChartOptions<"line"> = {
    responsive: true,
    aspectRatio: 1.5,
    plugins: {
        legend: {
            display: false,
        }
    },
    elements: {
        point: {
            radius: 0
        }
    },
    scales: {
        x: {
            ticks: {
                autoSkipPadding: 50,
                font: {
                    size: 20
                }
            }
        },
        y: {
            ticks: {
                font: {
                    size: 20
                }
            }
        }
    }
};