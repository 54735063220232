import BackendServiceBoundary from "../../backend/BackendService";
import useStockInfo from "./ResultViewModel";
import { UserFormInput } from "../form/UserForm";
import ResultGraph from './ResultGraph';
import { currencyFormatter, dateFormatter } from "../Formatters";
import ResultTable from "./ResultTable";
import ErrorModal from "../ui/ErrorModal";
import About from "../About";

export default function Results(props: InvestmentTableProps) {
  const { userInput, service } = props;

  const result = useStockInfo(service, userInput?.stockSymbol, userInput?.initialInvestment, userInput?.investmentDate);

  return (
    <>
      {result.report ? <>
        <ResultSummary
          initDate={result.report.investmentDate}
          symbol={result.report.stock.symbol}
          initPrice={result.report.investmentDatePrice}
          finalPrice={result.report.currentDatePrice}
          finalInvestmentValue={result.report.finalInvestmentValue}
          totalInterest={result.report.totalInterest}
        />
        <ResultGraph dataPoints={result.report.valueOverTimePoints} />
        <ResultTable
          dataPoints={result.report.valueOverTimePoints}
          initialInvestment={result.report.initialInvestmentValue}
          initialDatePrice={result.report.investmentDatePrice}
        />
      </> : <About/>
      }

      <ErrorModal message={result.error} />

    </>
  );

}

function ResultSummary(props: { initDate: Date, symbol: string, initPrice: number, finalPrice: number, finalInvestmentValue: number, totalInterest: number }) {
  const { initDate, symbol, initPrice, finalPrice, finalInvestmentValue, totalInterest } = props;

  const finalAmountFormatted = currencyFormatter.format(finalInvestmentValue);
  const initDateFormatted = dateFormatter.format(initDate);
  const initPriceFormatted = currencyFormatter.format(initPrice);
  const finalPriceFormatted = currencyFormatter.format(finalPrice);
  const totalInterestFormatted = totalInterest.toFixed(2);

  let summarySentence = 'Haha well looks like you would have made no money, the price is the same as today.';
  if (finalPrice > initPrice) {
    summarySentence = `Your investment would have been worth ${finalAmountFormatted} today! That's a total interest of ${totalInterestFormatted}%.`;
  } else if (finalPrice < initPrice) {
    summarySentence = `Good thing you didn't take that investment! It would be worth ${finalAmountFormatted} today...`;
  }

  return <>
    <h2 className="result-missing">{summarySentence}</h2>
    <p className="result-missing">{`On ${initDateFormatted} ${symbol} was worth ${initPriceFormatted}. Now it is worth ${finalPriceFormatted}.`}</p>
  </>;
}

type InvestmentTableProps = {
  userInput: UserFormInput | undefined;
  service: BackendServiceBoundary;
}