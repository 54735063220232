import { ChangeEventHandler, Dispatch, FormEventHandler, SetStateAction, useState } from "react";
import ReactGA from 'react-ga4';
import SymbolInput from "./SymbolInput";
import './form.css';

export default function UserForm(props: UserFormProps) {
    const { onSubmit: formSubmitHandler } = props;

    const [stockSymbol, setStockSymbol] = useState<string>('');
    const [investment, setInvestment] = useState<number>(0);
    const [date, setDate] = useState<Date>(new Date());

    function handleInputChange(setInput: Dispatch<SetStateAction<number>>): ChangeEventHandler<HTMLInputElement> {
        return (event) => setInput(event.target.valueAsNumber);
    };
    function handleInputChangeDate(setInput: Dispatch<SetStateAction<Date>>): ChangeEventHandler<HTMLInputElement> {
        return (event) => setInput(event.target.valueAsDate || new Date());
    };

    const submit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        ReactGA.event({
            category: 'User',
            action: 'Form Submit'
        });
        formSubmitHandler({
            stockSymbol,
            initialInvestment: investment,
            investmentDate: date,
        });
    };

    const reset = () => {
        setStockSymbol('');
        setInvestment(0);
        setDate(new Date());
    };

    return (
        <form className="form" onSubmit={submit} onReset={reset}>
            <div className="input-group">
                <p>
                    <label htmlFor="stock-symbol">Stock Symbol</label>
                    <SymbolInput 
                        id="stock-symbol"
                        stockSymbol={stockSymbol}
                        setStockSymbol={setStockSymbol}
                    />
                </p><p>
                    <label htmlFor="date">
                        Purchase Date
                    </label>
                    <input
                        type="date"
                        // value={date.toISOString().split('T')[0]}
                        id="date"
                        onChange={handleInputChangeDate(setDate)}
                        max={new Date().toISOString().split('T')[0]}
                    />
                </p>
                
            </div>
            <div className="input-group">
                <p>
                    <label htmlFor="investment">Investment ($)</label>
                    <input
                        type="number"
                        value={investment}
                        id="investment"
                        min={0}
                        onChange={handleInputChange(setInvestment)}
                    />
                </p>
            </div>
            <p className="actions">
                <button type="reset" className="buttonAlt">
                    Reset
                </button>
                <button type="submit" className="button">
                    Calculate
                </button>
            </p>
        </form>
    )
}

export type UserFormProps = {
    onSubmit: (input: UserFormInput) => void;
}

export type UserFormInput = {
    stockSymbol: string;
    initialInvestment: number;
    investmentDate: Date;
}