import { Stock } from "./Entities";
import BackendError from "./BackendError";
import { Axios, isAxiosError } from "axios";

export default interface BackendServiceBoundary {
    getStockInfo(symbol: string): Promise<Stock>;
    getStockInfoForRange(symbol: string, resultSize: number, beginDate: Date, endDate?: Date): Promise<Stock>;
}

export class BackendService implements BackendServiceBoundary {
    readonly #axios: Axios;

    constructor(axios: Axios) {
        this.#axios = axios;
    }

    async getStockInfo(symbol: string): Promise<Stock> {
        return this.#axios.get<StockResponse>(`/stock/${symbol}`).then(result => {
            const body = result.data;
            if (body) {
                return transformStockResponse(body);
            }
            throw new BackendError(`Cannot retrieve info for stock ${symbol}`);
        }).catch(err => {
            let msg = `Cannot retrieve info for stock ${symbol}`;
            if (isAxiosError(err)) {
                const respBody = err.response?.data;
                if (respBody.error) {
                    msg = respBody.error;
                } else {
                    msg = err.message;
                }
            }
            throw new BackendError(msg);
        });
    }

    async getStockInfoForRange(symbol: string, resultSize: number, beginDate: Date, endDate: Date = new Date()): Promise<Stock> {
        const beginTs = beginDate.getTime();
        const endTs = endDate.getTime();
        return this.#axios.get(`/stock/${symbol}/range/${beginTs}/${endTs}`).then(result => {
            if (result.status === 200 && result.data) {
                const body = JSON.parse(result.data) as StockResponse;
                if (!body.priceOverTime || body.priceOverTime.length < 1) {
                    throw new BackendError(`Cannot retrieve info for stock ${symbol}`);
                }
                return transformStockResponse(body);
            } else if (result.status === 400) {
                const error = JSON.parse(result.data) as ServiceError;
                throw new BackendError(error.error);
            }
            throw new BackendError(`Cannot retrieve info for stock ${symbol}`);
        }).catch(err => {
            let msg = `Cannot retrieve info for stock ${symbol}`;
            if (isAxiosError(err)) {
                const respBody = err.response?.data;
                if (respBody && respBody.error) {
                    msg = respBody.error;
                } else {
                    msg = err.message;
                }
            }
            throw new BackendError(msg);
        });
    }
}

function transformStockResponse(response: StockResponse): Stock {
    return {
        ...response,
        price: response.priceOverTime && response.priceOverTime[0] ? response.priceOverTime[0].close : response.price,
        date: new Date(response.date),
        firstTradeDate: new Date(response.firstTradeDate),
        priceOverTime: response.priceOverTime?.map(dp => ({ value: dp.close, date: new Date(dp.date) }))
    };
}

type StockResponse = {
    currency: string;
    currentMarketPrice: number;
    symbol: string;
    price: number;
    date: number;
    firstTradeDate: number;
    priceOverTime?: {
        open: number;
        close: number;
        date: number;
    }[];
}

type ServiceError = {
    error: string;
}