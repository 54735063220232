import logo from '../assets/cute-robot-logo.png';

export default function Header() {
    return (
        <header className="header">
            <img src={logo} alt="logo" />
            <h1>WhatIfIBot - Your Personal Stock Investment Tracker</h1>
            <p className='about'>Welcome to WhatIfIBot, your go-to destination for tracking the current value of your stock investments from the past. Whether you're a seasoned investor or just starting, our user-friendly platform provides you with a quick and accurate assessment of your stock portfolio's current worth.</p>
        </header>
    );
}