import { currencyFormatter, dateFormatter } from "../Formatters";

export default function ResultTable(props: ResultTableProps) {
    const { initialInvestment, initialDatePrice, dataPoints } = props;

    return <table className="result">
        <thead>
            <tr>
                <th>Date</th>
                <th>Stock Price ($ Per)</th>
                <th>Total Value</th>
            </tr>
        </thead>
        <tbody>
            {
                dataPoints.map(
                    row => <InvestRow key={row.date.getTime()} data={row} initInvestAmount={initialInvestment} initValue={initialDatePrice} />
                )
            }
        </tbody>
    </table>;
}

type ResultTableProps = {
    initialInvestment: number;
    initialDatePrice: number;
    dataPoints: {
        value: number;
        date: Date;
    }[];
}

function InvestRow(props: { data: { value: number, date: Date }, initInvestAmount: number, initValue: number }) {
    const { data, initInvestAmount, initValue } = props;
    const totalValue = (data.value / initValue) * initInvestAmount;
    return (
        <tr>
            <td>{dateFormatter.format(data.date)}</td>
            <td>{currencyFormatter.format(data.value)}</td>
            <td>{currencyFormatter.format(totalValue)}</td>
        </tr>
    );
}